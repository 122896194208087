import "./App.css";
import { useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import axios from "axios";
import { request, gql } from "graphql-request";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function App() {
  const [isLoading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    titlePage: "",
    title: {
      pl: "",
      en: "",
    },
    description: {
      pl: EditorState.createEmpty(),
      en: EditorState.createEmpty(),
    },
    category: 0,
    desktop: null,
    mobile: null,
    audio: null,
    photos: null,
    thumbnail: null,
  });

  const onInputChange = (e) =>
    setFormState({ ...formState, [e.target.name]: e.target.value });

  const onFileChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.files[0] });
  };

  const onFileArrayChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.files });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (
      !formState.title.pl ||
      !formState.title.en ||
      !formState.titlePage ||
      !formState.category
    ) {
      alert("Sprawdz wszystkie pola");
      return false;
    }
    const htmlDescriptionPL = draftToHtml(
      convertToRaw(formState.description.pl.getCurrentContent())
    );
    const htmlDescriptionEN = draftToHtml(
      convertToRaw(formState.description.en.getCurrentContent())
    );

    const CreateArt = gql`
      mutation(
        $art: ArtInput!
        $desktop: Upload!
        $mobile: Upload!
        $audio: Upload!
        $photos: [Upload]!
        $thumbnail: Upload!
      ) {
        createArt(
          art: $art
          desktop: $desktop
          mobile: $mobile
          audio: $audio
          photos: $photos
          thumbnail: $thumbnail
        ) {
          title {
            pl
            en
          }
          titlePage
          description {
            pl
            en
          }
          category
        }
      }
    `;

    const art = {
      titlePage: formState.titlePage,
      title: {
        pl: formState.title.pl,
        en: formState.title.en,
      },
      description: {
        pl: htmlDescriptionPL,
        en: htmlDescriptionEN,
      },
      category: parseInt(formState.category),
    };
    setLoading(true);
    request("https://api.museum.kubakoduje.pl/graphql", CreateArt, {
      art: art,
      desktop: formState.desktop,
      mobile: formState.mobile,
      audio: formState.audio,
      photos: formState.photos,
      thumbnail: formState.thumbnail
    })
      .then((data) => {
        alert("Udało się dodać obiekt");
        setLoading(false);
      })
      .catch((error) => {
        alert("Wystąpił błąd");
        setLoading(false);
      });
  };

  return (
    <>
      {isLoading && (
        <div className="loading-wrapper">
          <Loader
            type="Puff"
            color="#00BFFF"
            height={400}
            width={400}
            timeout={3000} //3 secs
          />
        </div>
      )}
      <h2>Formularz dodawania obiektu</h2>
      <form className={"div"}>
        <label>
          Tytuł strony w pasku url (np. pilsudski-maska)
          <input
            name="titlePage"
            type={"text"}
            placeholder={"Tytuł strony"}
            onChange={onInputChange}
          />
        </label>
        <label>
          Tytuł obiektu polski
          <input
            value={formState.title.pl}
            onChange={(e) =>
              setFormState({
                ...formState,
                title: { ...formState.title, pl: e.target.value },
              })
            }
            type={"text"}
            placeholder={"Tytuł obiektu polski"}
          />
        </label>
        <label>
          Tytuł obiektu angielski
          <input
            value={formState.title.en}
            type={"text"}
            placeholder={"Tytuł obiektu angielski"}
            onChange={(e) =>
              setFormState({
                ...formState,
                title: { ...formState.title, en: e.target.value },
              })
            }
          />
        </label>
        <label>
          Opis obiektu polski
          <Editor
            editorState={formState.description.pl}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            stripPastedStyle={true}
            handlePastedText={() => false}
            toolbar={{
              options: ["inline", "list", "history", "link"],
            }}
            onEditorStateChange={(editorState) =>
              setFormState({
                ...formState,
                description: { ...formState.description, pl: editorState },
              })
            }
          />
        </label>
        <label>
          Opis obiektu angielski
          <Editor
            editorState={formState.description.en}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            stripPastedStyle={true}
            handlePastedText={() => false}
            toolbar={{
              options: ["inline", "list", "history", "link"],
            }}
            onEditorStateChange={(editorState) =>
              setFormState({
                ...formState,
                description: { ...formState.description, en: editorState },
              })
            }
          />
        </label>
        <label>
          Kategoria
          <select
            name="category"
            value={formState.category}
            onChange={onInputChange}
          >
            <option hidden value={""}>
              Kategoria
            </option>
            <option value={1}>Przedstawienie postaci</option>
            <option value={2}>Marszałek Józef Piłsudski</option>
            <option value={3}>Płaskorzeźby</option>
            <option value={4}>Pozostałe dzieła</option>
          </select>
        </label>
        <label>
          Model komputerowy
          <input name="desktop" type={"file"} onChange={onFileChange} />
        </label>
        <label>
          Model mobilny
          <input name="mobile" type={"file"} onChange={onFileChange} />
        </label>
        <label>
          Audio
          <input name="audio" type={"file"} onChange={onFileChange} />
        </label>
        <label>
          thumbnail
          <input name="thumbnail" type={"file"} onChange={onFileChange} />
        </label>
        <label>
          Zdjęcia
          <input
            name="photos"
            type={"file"}
            multiple={true}
            onChange={onFileArrayChange}
          />
        </label>
        <button onClick={onSubmitForm} type="submit">
          Wyślij
        </button>
      </form>
    </>
  );
}

export default App;
